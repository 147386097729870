import { TitleCard } from '../../screens/dashboard';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import Highcharts from 'highcharts';
import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import {
  generateArrays,
  generateDataTable,
  hChartConfig,
  hColors,
} from '../../helpers/chart';
import {
  fillNullValues,
  generateSeriesDataZone,
  mergeArraysAndSkipDuplicates,
  roundTimestampsToNearestMinute,
  roundValue,
  toHHMM,
  toHHMMSS,
  zoneChartType,
} from '../../helpers/dateUtil';
import React from 'react';
import useFilter from '../../hooks/useFilter';
import { IFlowmeterData, IRPMData, ISummary } from '../../types/summary.type';
import { EmptyChart } from './HChartAEFuelCons';
import { FlexHeader } from './HChartRpmFuelSpeed';
import ChartTypeSwitch from './ChartTypeSwitch';
import useAsset from '../../hooks/useAsset';
import { subtitleDottedLine } from '../../helpers/constant';

highchartsMore(Highcharts);

interface IProps {
  data?: ISummary;
  type: 'PORT' | 'STARBOARD' | 'MAINENGINE';
  isDailyReport?: boolean;
  columns: number[];
  isSingleEngine?: boolean;
  setExtremeData?: (value: 'reset' | 'zoom') => void;
}

const yAxisOne = [
  {
    color: hColors.orange,
    text: 'Fuel Cons.',
  },
  {
    color: hColors.pink,
    text: 'Running Time',
    fixed: true,
    opposite: true,
  },
];

const HChartFuelRunningHour = React.forwardRef<any, IProps>(
  ({ data, type, isDailyReport, columns, setExtremeData }, ref) => {
    const [dataChart, setDataChart] = useState<any>();
    const chartRef: any = useRef({});
    const { dataFilter } = useFilter();
    const { chartType } = useAsset();

    const generateYAxis = (
      data: {
        color: string;
        text: string;
        opposite?: boolean;
        fixed?: boolean;
      }[]
    ) => {
      const yAxis: Highcharts.YAxisOptions | Array<Highcharts.YAxisOptions> =
        [];
      for (let idx = 0; idx < data.length; idx++) {
        const item = data[idx];
        yAxis.push({
          labels: {
            format: '{value}',
            style: {
              color:
                item.text === 'Nautical Miles'
                  ? type === 'PORT'
                    ? hColors.purple
                    : type === 'STARBOARD'
                    ? hColors.teal
                    : hColors.pink
                  : item.color,
            },
            formatter(
              this: Highcharts.AxisLabelsFormatterContextObject,
              ctx: Highcharts.AxisLabelsFormatterContextObject
            ) {
              if (item.fixed) {
                return Number(this.value).toFixed(2);
              }
              return this.value.toString();
            },
          },
          title: {
            text: item.text,
            style: {
              color:
                item.text === 'Nautical Miles'
                  ? type === 'PORT'
                    ? hColors.purple
                    : type === 'STARBOARD'
                    ? hColors.teal
                    : hColors.pink
                  : item.color,
            },
          },
          lineColor:
            item.text === 'Nautical Miles'
              ? type === 'PORT'
                ? hColors.purple
                : type === 'STARBOARD'
                ? hColors.teal
                : hColors.pink
              : item.color,
          lineWidth: 1,
          tickColor:
            item.text === 'Nautical Miles'
              ? type === 'PORT'
                ? hColors.purple
                : type === 'STARBOARD'
                ? hColors.teal
                : hColors.pink
              : item.color,
          tickWidth: 1,
          tickLength: 5,
          opposite: item.opposite,
        });
      }

      return yAxis;
    };

    useEffect(() => {
      if (data) {
        const createDataSeries = () => {
          let currChartOption: any = {
            ...hChartConfig,
            yAxis: generateYAxis(yAxisOne),
            tooltip: {
              formatter(this: Highcharts.TooltipFormatterContextObject) {
                return (
                  this.points &&
                  this.points.reduce(function (s, point) {
                    let value = point.y?.toFixed(2);
                    if (point.series.name.toLowerCase().includes('hour')) {
                      value = toHHMMSS((point.y || 0) * 3600);
                    }
                    return s + '<br/>' + point.series.name + ': ' + value;
                  }, '<b>' + this.x + '</b>')
                );
              },
              shared: true,
              crosshairs: true,
            },
          };

          let newCategories: any[] = mergeArraysAndSkipDuplicates(
            data.rpm?.timestamps || [],
            data.flowmeter?.timestamps || []
          ).sort((a, b) => a - b);

          // let newCategories: any[] = generateTimestampArray(
          //   moment(dataFilter.range.startAt).unix(),
          //   moment(dataFilter.range.endAt).unix(),
          //   dataFilter.interval === 'MINUTE'
          //     ? 60
          //     : dataFilter.interval === 'HOUR'
          //     ? 3600
          //     : 86400
          // );

          const dataSeries: any[] = [];

          Object.keys(data).forEach((key) => {
            let currDataSeriesFuelCons: any = [];
            let currDataSeriesRunningHours: any = [];

            if (
              key === 'flowmeter' &&
              data['flowmeter']?.data &&
              data['flowmeter']?.data.length > 0
            ) {
              if (type === 'MAINENGINE') {
                newCategories.forEach((item: any) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IFlowmeterData) =>
                      data &&
                      data.PORT_IN &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data.PORT_IN.timestamp * 1000
                        ) /
                          1000
                  );

                  if (isTimestampSame) {
                    currDataSeriesFuelCons.push(isTimestampSame.meFuelCons);
                  } else {
                    currDataSeriesFuelCons.push(undefined);
                  }
                });
              } else if (type === 'PORT') {
                newCategories.forEach((item: any) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IFlowmeterData) =>
                      data &&
                      data.PORT_IN &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data.PORT_IN.timestamp * 1000
                        ) /
                          1000
                  );

                  if (isTimestampSame) {
                    currDataSeriesFuelCons.push(
                      isTimestampSame.PORT_FuelConsVolume
                    );
                  } else {
                    currDataSeriesFuelCons.push(undefined);
                  }
                });
              } else if (type === 'STARBOARD') {
                newCategories.forEach((item: any) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IFlowmeterData) =>
                      data &&
                      data.STARBOARD_IN &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data.STARBOARD_IN.timestamp * 1000
                        ) /
                          1000
                  );

                  if (isTimestampSame) {
                    currDataSeriesFuelCons.push(
                      isTimestampSame.STARBOARD_FuelConsVolume
                    );
                  } else {
                    currDataSeriesFuelCons.push(undefined);
                  }
                });
              }

              dataSeries.push({
                name: `${type} Fuel Cons.`,
                type: chartType,
                yAxis: 0,
                data: currDataSeriesFuelCons,
                marker: {
                  enabled: true,
                  symbol: 'circle',
                },
                color: hColors.yellow,
                zIndex: 1,
              });
            }

            if (key === 'rpm' && data['rpm']) {
              newCategories.forEach((item: any) => {
                const isTimestampSame = data[key]?.data.find(
                  (data: IRPMData) => {
                    if (
                      data[type] &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data[type].timestamp * 1000
                        ) /
                          1000
                    ) {
                      return data;
                    }
                  }
                );

                if (isTimestampSame) {
                  currDataSeriesRunningHours.push(
                    isTimestampSame[type]
                      ? isTimestampSame[type].runningTime / 3600
                      : 0
                  );
                } else {
                  currDataSeriesRunningHours.push(undefined);
                }
              });

              dataSeries.push({
                name: `${type} Running Hour`,
                type: 'line',
                yAxis: 1,
                data: currDataSeriesRunningHours,
                color: type === 'PORT' ? hColors.purple : hColors.teal,
                zIndex: 3,
              });
            }
          });

          const newDataCategories = newCategories.map((item) =>
            moment(item * 1000).format('DD/MM/YY HH:mm')
          );

          const newDataSeries = dataSeries.map((serie: any) => {
            const data = [...serie.data].map((value: number | undefined) => {
              return typeof value === 'undefined'
                ? null
                : (value && typeof value === 'number') || value === 0
                ? Number(value.toFixed(2))
                : null;
            });

            const newData = fillNullValues(data);

            return {
              ...serie,
              data: newData,
              zoneAxis: 'x',
              zones: zoneChartType.includes(serie.type)
                ? generateSeriesDataZone({
                    data,
                    color: serie.color,
                    type: serie.type,
                  })
                : [],
            };
          });

          currChartOption = {
            ...currChartOption,
            xAxis: [
              {
                categories: newDataCategories,
                crosshair: true,
                // events: {
                //   afterSetExtremes: function (
                //     this: Highcharts.Axis,
                //     evt: Highcharts.AxisSetExtremesEventObject
                //   ) {
                //     fetchDataAfterSetExtreme(evt);
                //   },
                // },
              },
            ],
            series: newDataSeries,
          };
          setDataChart(currChartOption);
        };

        createDataSeries();
      }
    }, [data, type, chartType]);

    const dataTable = useMemo(() => {
      if (data) {
        return generateDataTable(data);
      }
    }, [data]);

    // console.log('CHART FUEL RUNNING HOUR - raw data: ', data);
    // console.log('CHART FUEL RUNNING HOUR - data chart:', dataChart);

    return (
      <>
        {!isDailyReport && (
          <FlexHeader justify='space-between'>
            <TitleCard
              title={
                type === 'PORT'
                  ? 'Port Fuel Used vs Running Hour'
                  : type === 'STARBOARD'
                  ? 'Starboard Fuel Used vs Running Hour'
                  : 'ME Fuel Used vs Running Hour'
              }
              subtitle={!isDailyReport && subtitleDottedLine}
            />
            <ChartTypeSwitch />
          </FlexHeader>
        )}
        <div ref={ref} style={{ height: '100%' }}>
          {dataChart && dataChart.series.length === 0 ? (
            <EmptyChart>
              <h3>Empty Data</h3>
              <p>Data series empty</p>
            </EmptyChart>
          ) : (
            <HighchartsReact
              ref={chartRef}
              containerProps={{
                style: {
                  height: isDailyReport ? '70%' : '90%',
                },
              }}
              highcharts={Highcharts}
              options={{
                ...dataChart,
                chart: {
                  zoomType: 'xy',
                  events: {
                    // load: function (this: Highcharts.Chart) {
                    //   const chart = this;
                    //   Highcharts.addEvent(
                    //     chart.container,
                    //     'wheel',
                    //     function (e: WheelEvent) {
                    //       const xAxis = chart.xAxis[0],
                    //         extremes = xAxis.getExtremes(),
                    //         newMin = extremes.min;
                    //       console.log(extremes);
                    //       console.log(newMin);
                    //       if (e.deltaY < 0) {
                    //         xAxis.setExtremes(
                    //           extremes.min - extremes.min * 0.1,
                    //           extremes.max,
                    //           true
                    //         );
                    //       } else {
                    //         xAxis.setExtremes(
                    //           extremes.min + extremes.min * 0.1,
                    //           extremes.max,
                    //           true
                    //         );
                    //       }
                    //     }
                    //   );
                    // },
                  },
                },
              }}
              allowChartUpdate={true}
              immutable={true}
            />
          )}

          {isDailyReport &&
            dataFilter.interval === 'HOUR' &&
            data &&
            dataTable && (
              <table
                className='custom-table-daily-report'
                style={{
                  width: isDailyReport ? '100%' : '90%',
                  margin: '0 auto',
                }}
              >
                <tbody>
                  <tr>
                    <th>Hour</th>
                    {columns.map((item) => (
                      <td key={item}>{item}</td>
                    ))}
                  </tr>
                  <tr>
                    <th>
                      {type === 'PORT'
                        ? 'Port Fuel Cons.'
                        : type === 'STARBOARD'
                        ? 'Stb. Fuel Cons.'
                        : 'ME Fuel Cons.'}
                    </th>
                    {dataTable.flowmeter && dataTable.flowmeter.data.length > 0
                      ? (dataTable?.flowmeter.data)
                          .filter(
                            (item) =>
                              item.PORT_IN.timestamp ||
                              item.STARBOARD_IN.timestamp
                          )
                          .map((item: IFlowmeterData, idx: number) => (
                            <td key={`fuel-${idx}`}>
                              {type === 'PORT'
                                ? roundValue(item.PORT_FuelConsVolume)
                                : type === 'STARBOARD'
                                ? roundValue(item.STARBOARD_FuelConsVolume)
                                : roundValue(item.ME_FuelCons)}
                            </td>
                          ))
                      : generateArrays(0, columns ? columns?.length : 24).map(
                          (item) => <td>{item}</td>
                        )}
                  </tr>
                  <tr>
                    <th>
                      {type === 'PORT'
                        ? 'Port Running Hour'
                        : type === 'STARBOARD'
                        ? 'Stb. Running Hour'
                        : 'ME Running Hour'}
                    </th>
                    {dataTable.rpm &&
                    dataTable.rpm.data &&
                    dataTable.rpm.data.length > 0
                      ? dataTable.rpm.data.map(
                          (item: IRPMData, idx: number) => (
                            <td key={`hour-${idx}`}>
                              {type === 'PORT' &&
                              item['PORT'] &&
                              item['PORT'].runningTime
                                ? toHHMM(item['PORT'].runningTime)
                                : type === 'STARBOARD' &&
                                  item['STARBOARD'] &&
                                  item['STARBOARD'].runningTime
                                ? toHHMM(item['STARBOARD'].runningTime)
                                : type === 'MAINENGINE' &&
                                  item['MAINENGINE'] &&
                                  item['MAINENGINE'].runningTime
                                ? toHHMM(item['MAINENGINE'].runningTime)
                                : 0}
                            </td>
                          )
                        )
                      : generateArrays(0, columns ? columns?.length : 24).map(
                          (item) => <td>{item}</td>
                        )}
                  </tr>
                </tbody>
              </table>
            )}
        </div>
      </>
    );
  }
);

export default HChartFuelRunningHour;
