import { TitleCard } from '../../screens/dashboard';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import Highcharts from 'highcharts';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  generateArrays,
  generateDataTable,
  hChartConfig,
  hColors,
} from '../../helpers/chart';
import React from 'react';
import useFilter from '../../hooks/useFilter';
import {
  IFlowmeterData,
  IGPSData,
  IRPMData,
  ISummary,
} from '../../types/summary.type';
import {
  fillNullValues,
  generateSeriesDataZone,
  mergeArraysAndSkipDuplicates,
  roundTimestampsToNearestMinute,
  roundValue,
  zoneChartType,
  toKnot,
} from '../../helpers/dateUtil';
import { EmptyChart } from './HChartAEFuelCons';
import { FlexHeader } from './HChartRpmFuelSpeed';
import ChartTypeSwitch from './ChartTypeSwitch';
import useAsset from '../../hooks/useAsset';
import { subtitleDottedLine } from '../../helpers/constant';

highchartsMore(Highcharts);

interface IProps {
  data?: ISummary;
  isDailyReport?: boolean;
  isSingleEngine?: boolean;
  columns: number[];
}

const HChartMeFuelCons = React.forwardRef<any, IProps>(
  ({ data, isDailyReport, isSingleEngine, columns }, ref) => {
    const [dataChart, setDataChart] = useState<any>();
    const [summary, setSummary] = useState<{ max: number; avg: number }>({
      max: 0,
      avg: 0,
    });
    const { dataFilter } = useFilter();
    const { chartType } = useAsset();

    const generateYAxis = () => {
      let yAxis: any = [];
      if (!isSingleEngine) {
        yAxis = [
          {
            labels: {
              format: '{value}',
              style: {
                color: hColors.black,
              },
            },
            title: {
              text: 'RPM',
              style: {
                color: hColors.black,
              },
            },
            lineColor: hColors.black,
            lineWidth: 1,
            tickColor: hColors.black,
            tickWidth: 1,
            tickLength: 5,
          },
          {
            labels: {
              format: '{value}',
              style: {
                color: hColors.black,
              },
            },
            title: {
              text: 'Liter',
              style: {
                color: hColors.black,
              },
            },
            lineColor: hColors.black,
            lineWidth: 1,
            tickColor: hColors.black,
            tickWidth: 1,
            tickLength: 5,
            opposite: true,
          },
          {
            labels: {
              format: '{value}',
              style: {
                color: hColors.pink,
              },
            },
            title: {
              text: 'Knot',
              style: {
                color: hColors.pink,
              },
            },
            lineColor: hColors.pink,
            lineWidth: 1,
            tickColor: hColors.pink,
            tickWidth: 1,
            tickLength: 5,
            opposite: true,
          },
        ];
      } else {
        yAxis = [
          {
            labels: {
              format: '{value}',
              style: {
                color: hColors.black,
              },
            },
            title: {
              text: 'RPM',
              style: {
                color: hColors.black,
              },
            },
            lineColor: hColors.black,
            lineWidth: 1,
            tickColor: hColors.black,
            tickWidth: 1,
            tickLength: 5,
          },
          {
            labels: {
              format: '{value}',
              style: {
                color: hColors.black,
              },
            },
            title: {
              text: 'Liter',
              style: {
                color: hColors.black,
              },
            },
            lineColor: hColors.black,
            lineWidth: 1,
            tickColor: hColors.black,
            tickWidth: 1,
            tickLength: 5,
            opposite: true,
          },
          {
            labels: {
              format: '{value}',
              style: {
                color: hColors.pink,
              },
            },
            title: {
              text: 'Knot',
              style: {
                color: hColors.pink,
              },
            },
            lineColor: hColors.pink,
            lineWidth: 1,
            tickColor: hColors.pink,
            tickWidth: 1,
            tickLength: 5,
            opposite: true,
          },
        ];
      }

      return yAxis;
    };

    const dataTable = useMemo(() => {
      if (data) {
        return generateDataTable(data);
      }
    }, [data]);

    useEffect(() => {
      if (data) {
        const createDataSeries = () => {
          let currChartOption: any = {
            ...hChartConfig,
            yAxis: generateYAxis(),
          };

          // const categories = data.flowmeter?.data.map((fm: IFlowmeterData) => {
          //   let timestamp = 0;
          //   if (fm.PORT_IN) {
          //     timestamp = fm.PORT_IN.timestamp;
          //   } else if (fm.PORT_OUT) {
          //     timestamp = fm.PORT_OUT.timestamp;
          //   } else if (fm.STARBOARD_IN) {
          //     timestamp = fm.STARBOARD_IN.timestamp;
          //   } else if (fm.STARBOARD_OUT) {
          //     timestamp = fm.STARBOARD_OUT.timestamp;
          //   }
          //   return moment(timestamp * 1000).format('DD/MM/YY HH:mm');
          // });

          let newCategories: any[] = mergeArraysAndSkipDuplicates(
            data.flowmeter?.timestamps || []
          ).sort((a, b) => a - b);

          const dataSeries: any = [];
          let allPortStbFuelCons: number[] = [];
          let allMeFuelConsData: number[] = [];

          interface FCTData {
            massId: string;
            FCTData: {
              lowerRpm: number;
              topRpm: number;
              fuelCons: {
                port: number;
                starboard: number;
              };
            }[];
          }

          let expectedFuelCons: FCTData[] = [
            {
              massId: '32',
              FCTData: [
                {
                  lowerRpm: 600,
                  topRpm: 700,
                  fuelCons: {
                    port: 0.274,
                    starboard: 0.22,
                  },
                },
                {
                  lowerRpm: 701,
                  topRpm: 800,
                  fuelCons: {
                    port: 0.311,
                    starboard: 0.289,
                  },
                },
                {
                  lowerRpm: 801,
                  topRpm: 900,
                  fuelCons: {
                    port: 0.389,
                    starboard: 0.347,
                  },
                },
                {
                  lowerRpm: 901,
                  topRpm: 1000,
                  fuelCons: {
                    port: 0.519,
                    starboard: 0.474,
                  },
                },
                {
                  lowerRpm: 1001,
                  topRpm: 1100,
                  fuelCons: {
                    port: 0.608,
                    starboard: 0.578,
                  },
                },
                {
                  lowerRpm: 1101,
                  topRpm: 1200,
                  fuelCons: {
                    port: 0.781,
                    starboard: 0.714,
                  },
                },
                {
                  lowerRpm: 1201,
                  topRpm: 1300,
                  fuelCons: {
                    port: 1.093,
                    starboard: 0.921,
                  },
                },
                {
                  lowerRpm: 1301,
                  topRpm: Infinity,
                  fuelCons: {
                    port: 1.646,
                    starboard: 1.22,
                  },
                },
              ],
            },
            {
              massId: '33',
              FCTData: [
                {
                  lowerRpm: 600,
                  topRpm: 700,
                  fuelCons: {
                    port: 0.114,
                    starboard: 0.14,
                  },
                },
                {
                  lowerRpm: 701,
                  topRpm: 800,
                  fuelCons: {
                    port: 0.175,
                    starboard: 0.207,
                  },
                },
                {
                  lowerRpm: 801,
                  topRpm: 900,
                  fuelCons: {
                    port: 0.208,
                    starboard: 0.273,
                  },
                },
                {
                  lowerRpm: 901,
                  topRpm: 1000,
                  fuelCons: {
                    port: 0.305,
                    starboard: 0.392,
                  },
                },
                {
                  lowerRpm: 1001,
                  topRpm: 1100,
                  fuelCons: {
                    port: 0.397,
                    starboard: 0.484,
                  },
                },
                {
                  lowerRpm: 1101,
                  topRpm: 1200,
                  fuelCons: {
                    port: 0.517,
                    starboard: 0.617,
                  },
                },
                {
                  lowerRpm: 1201,
                  topRpm: 1300,
                  fuelCons: {
                    port: 0.674,
                    starboard: 0.813,
                  },
                },
                {
                  lowerRpm: 1301,
                  topRpm: Infinity,
                  fuelCons: {
                    port: 0.89,
                    starboard: 1.007,
                  },
                },
              ],
            },
          ];

          Object.keys(data).forEach((key) => {
            if (key === 'rpm') {
              if (!isSingleEngine) {
                const portDataSeries: (number | undefined)[] = [];
                const portFCTSeries: (number | undefined)[] = [];

                newCategories.forEach((item: any) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IRPMData) =>
                      data['PORT'] &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data['PORT'].timestamp * 1000
                        ) /
                          1000
                  );

                  if (isTimestampSame) {
                    portDataSeries.push(isTimestampSame['PORT'].rpm);

                    const checkMassId = expectedFuelCons.find(
                      (item) => item.massId === dataFilter.massId
                    );

                    if (dataFilter.interval === 'HOUR') {
                      if (checkMassId) {
                        const rpm = isTimestampSame['PORT'].rpm;
                        const dataFound = checkMassId.FCTData.find((data) => {
                          return rpm >= data.lowerRpm && rpm <= data.topRpm;
                        });

                        if (dataFound) {
                          portFCTSeries.push(dataFound.fuelCons.port * 60);
                        } else {
                          portFCTSeries.push(0);
                        }
                      }
                    }
                  } else {
                    portDataSeries.push(undefined);
                  }
                });

                dataSeries.push({
                  name: 'Port RPM',
                  type: chartType,
                  yAxis: 0,
                  data: portDataSeries,
                  tooltip: {
                    valueSuffix: ' RPM',
                  },
                  color: hColors.purpleOpacity,
                  borderColor: hColors.purple,
                  zIndex: 3,
                });

                if (dataFilter.interval === 'HOUR') {
                  dataSeries.push({
                    name: 'Port FCT',
                    type: 'spline',
                    yAxis: 2,
                    data: portFCTSeries,
                    tooltip: {
                      valueSuffix: ' Liter',
                    },
                    color: hColors.limeYellow,
                    borderColor: hColors.limeYellow,
                    zIndex: 4,
                  });
                }
                const stbDataSeries: (number | undefined)[] = [];
                const stbFCTSeries: (number | undefined)[] = [];

                newCategories.forEach((item: any) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IRPMData) =>
                      data['STARBOARD'] &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data['STARBOARD'].timestamp * 1000
                        ) /
                          1000
                  );

                  if (isTimestampSame) {
                    stbDataSeries.push(isTimestampSame['STARBOARD'].rpm);

                    const checkMassId = expectedFuelCons.find(
                      (item) => item.massId === dataFilter.massId
                    );

                    if (dataFilter.interval === 'HOUR') {
                      if (checkMassId) {
                        const rpm = isTimestampSame['STARBOARD'].rpm;
                        const dataFound = checkMassId.FCTData.find((data) => {
                          return rpm >= data.lowerRpm && rpm <= data.topRpm;
                        });

                        if (dataFound) {
                          stbFCTSeries.push(dataFound.fuelCons.starboard * 60);
                        } else {
                          stbFCTSeries.push(0);
                        }
                      }
                    }
                  } else {
                    stbDataSeries.push(undefined);
                  }
                });

                dataSeries.push({
                  name: 'Starboard RPM',
                  type: chartType,
                  yAxis: 0,
                  data: stbDataSeries,
                  tooltip: {
                    valueSuffix: ' RPM',
                  },
                  color: hColors.tealOpacity,
                  borderColor: hColors.teal,
                  zIndex: 3,
                });

                if (dataFilter.interval === 'HOUR') {
                  dataSeries.push({
                    name: 'STB. FCT',
                    type: 'spline',
                    yAxis: 2,
                    data: stbFCTSeries,
                    tooltip: {
                      valueSuffix: ' Liter',
                    },
                    color: hColors.gray,
                    zIndex: 4,
                  });
                }
              } else {
                const meDataSeries: (number | undefined)[] = [];

                newCategories.forEach((item: any) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IRPMData) => {
                      if (
                        data['MAINENGINE'] &&
                        item ===
                          roundTimestampsToNearestMinute(
                            data['MAINENGINE'].timestamp * 1000
                          ) /
                            1000
                      ) {
                        return data;
                      } else if (
                        data['PORT'] &&
                        item ===
                          roundTimestampsToNearestMinute(
                            data['PORT'].timestamp * 1000
                          ) /
                            1000
                      ) {
                        return data;
                      } else if (
                        data['STARBOARD'] &&
                        item ===
                          roundTimestampsToNearestMinute(
                            data['STARBOARD'].timestamp * 1000
                          ) /
                            1000
                      ) {
                        return data;
                      }
                    }
                  );

                  if (isTimestampSame) {
                    meDataSeries.push(
                      isTimestampSame['MAINENGINE']
                        ? isTimestampSame['MAINENGINE'].rpm
                        : isTimestampSame['PORT']
                        ? isTimestampSame['PORT'].rpm
                        : isTimestampSame['STARBOARD']
                        ? isTimestampSame['STARBOARD'].rpm
                        : 0
                    );
                  } else {
                    meDataSeries.push(undefined);
                  }
                });

                dataSeries.push({
                  name: 'Main Engine RPM',
                  type: chartType,
                  yAxis: 0,
                  data: meDataSeries,
                  tooltip: {
                    valueSuffix: ' RPM',
                  },
                  color: hColors.purpleOpacity,
                  borderColor: hColors.purple,
                  zIndex: 3,
                });
              }
            }

            if (key === 'gps') {
              const gpsDataSeries: (number | undefined)[] = [];

              if (data[key]) {
                newCategories.forEach((item: any) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IGPSData) =>
                      data &&
                      item ===
                        roundTimestampsToNearestMinute(data.timestamp * 1000) /
                          1000
                  );

                  if (isTimestampSame) {
                    gpsDataSeries.push(
                      isTimestampSame.speed
                        ? parseFloat(toKnot(isTimestampSame.speed))
                        : 0
                    );
                  } else {
                    gpsDataSeries.push(undefined);
                  }
                });
              }

              dataSeries.push({
                name: 'Speed',
                type: 'spline',
                yAxis: 2,
                data: gpsDataSeries,
                marker: {
                  enabled: false,
                  // symbol: 'circle',
                  radius: 4,
                  lineColor: hColors.pink,
                  lineWidth: 3,
                  fillColor: hColors.pink,
                },
                tooltip: {
                  valueSuffix: ' knot',
                },
                lineWidth: 3,
                color: hColors.pink,
                zIndex: 4,
              });
            }

            if (
              key === 'flowmeter' &&
              data['flowmeter']?.data &&
              data['flowmeter']?.data.length > 0
            ) {
              if (!isSingleEngine) {
                data['flowmeter'].data.forEach((item) => {
                  allPortStbFuelCons.push(
                    item.PORT_IN &&
                      item.PORT_IN.timestamp &&
                      item.PORT_FuelConsVolume
                      ? item.PORT_FuelConsVolume
                      : 0
                  );

                  allPortStbFuelCons.push(
                    item.STARBOARD_IN &&
                      item.STARBOARD_IN.timestamp &&
                      item.STARBOARD_FuelConsVolume
                      ? item.STARBOARD_FuelConsVolume
                      : 0
                  );

                  allMeFuelConsData.push(
                    item.PORT_IN &&
                      item.PORT_IN.timestamp &&
                      item.STARBOARD_IN &&
                      item.STARBOARD_IN.timestamp
                      ? item.meFuelCons
                      : 0
                  );
                });

                console.log('0 allPortStbFuelCons', allPortStbFuelCons);

                // MULTILPE ENGINE
                const portFuelConsDataSeries: (number | undefined)[] = [];
                const stbFuelConsDataSeries: (number | undefined)[] = [];

                if (data[key]) {
                  newCategories.forEach((item: any) => {
                    const isTimestampSame = data[key]?.data.find(
                      (data: IFlowmeterData) =>
                        data &&
                        data.PORT_IN &&
                        item ===
                          roundTimestampsToNearestMinute(
                            data.PORT_IN.timestamp * 1000
                          ) /
                            1000
                    );

                    if (isTimestampSame) {
                      portFuelConsDataSeries.push(
                        isTimestampSame.PORT_FuelConsVolume < 0
                          ? 0
                          : isTimestampSame.PORT_FuelConsVolume
                      );
                    } else {
                      portFuelConsDataSeries.push(undefined);
                    }
                  });
                }

                if (data[key]) {
                  newCategories.forEach((item: any) => {
                    const isTimestampSame = data[key]?.data.find(
                      (data: IFlowmeterData) =>
                        data &&
                        data.STARBOARD_IN &&
                        item ===
                          roundTimestampsToNearestMinute(
                            data.STARBOARD_IN.timestamp * 1000
                          ) /
                            1000
                    );

                    if (isTimestampSame) {
                      stbFuelConsDataSeries.push(
                        isTimestampSame.STARBOARD_FuelConsVolume < 0
                          ? 0
                          : isTimestampSame.STARBOARD_FuelConsVolume
                      );
                    } else {
                      stbFuelConsDataSeries.push(undefined);
                    }
                  });
                }

                // Port Fuel Cons
                dataSeries.push({
                  name: 'Port Fuel Cons.',
                  type: 'spline',
                  yAxis: 2,
                  data: portFuelConsDataSeries,
                  tooltip: {
                    valueSuffix: ' Liter',
                  },
                  color: hColors.orange,
                  zIndex: 3,
                });

                // Starboard Fuel Cons
                dataSeries.push({
                  name: 'Stb. Fuel Cons.',
                  type: 'spline',
                  yAxis: 2,
                  data: stbFuelConsDataSeries,
                  tooltip: {
                    valueSuffix: ' Liter',
                  },
                  color: hColors.black,
                  zIndex: 3,
                });
              } else {
                data['flowmeter'].data.forEach((item) => {
                  allMeFuelConsData.push(
                    item.meFuelCons ||
                      item.PORT_FuelConsVolume ||
                      item.STARBOARD_FuelConsVolume
                  );
                });

                const meFuelConsDataSeries: (number | undefined)[] = [];

                if (data[key]) {
                  newCategories.forEach((item: any) => {
                    const isTimestampSame = data[key]?.data.find(
                      (data: IFlowmeterData) =>
                        data &&
                        data.PORT_IN &&
                        item ===
                          roundTimestampsToNearestMinute(
                            data.PORT_IN.timestamp * 1000
                          ) /
                            1000
                    );

                    if (isTimestampSame) {
                      const meFuelCons =
                        isTimestampSame.PORT_FuelConsVolume +
                        (isTimestampSame.STARBOARD_FuelConsVolume || 0);
                      meFuelConsDataSeries.push(
                        meFuelCons < 0 ? 0 : meFuelCons
                      );
                    } else {
                      meFuelConsDataSeries.push(undefined);
                    }
                  });
                }

                // SINGLE ENGINE
                dataSeries.push({
                  name: 'ME. Fuel Cons.',
                  type: chartType,
                  yAxis: 1,
                  data: meFuelConsDataSeries,
                  tooltip: {
                    valueSuffix: ' Liter',
                  },
                  color: hColors.black,
                  zIndex: 3,
                });
              }
            }

            if (key === 'rpm' && isSingleEngine) {
              const meDataSeries: (number | undefined)[] = [];

              newCategories.forEach((item: any) => {
                const isTimestampSame = data[key]?.data.find(
                  (data: IRPMData) => {
                    if (
                      data['MAINENGINE'] &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data['MAINENGINE'].timestamp * 1000
                        ) /
                          1000
                    ) {
                      return data;
                    } else if (
                      data['PORT'] &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data['PORT'].timestamp * 1000
                        ) /
                          1000
                    ) {
                      return data;
                    } else if (
                      data['STARBOARD'] &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data['STARBOARD'].timestamp * 1000
                        ) /
                          1000
                    ) {
                      return data;
                    }
                  }
                );

                if (isTimestampSame) {
                  meDataSeries.push(
                    isTimestampSame['MAINENGINE']
                      ? isTimestampSame['MAINENGINE'].rpm
                      : isTimestampSame['PORT']
                      ? isTimestampSame['PORT'].rpm
                      : isTimestampSame['STARBOARD']
                      ? isTimestampSame['STARBOARD'].rpm
                      : 0
                  );
                } else {
                  meDataSeries.push(undefined);
                }
              });

              dataSeries.push({
                name: 'ME. RPM',
                type: chartType,
                yAxis: 0,
                data: meDataSeries,
                tooltip: {
                  valueSuffix: ' RPM',
                },
                color: hColors.purpleOpacity,
                borderColor: hColors.purple,
                zIndex: 3,
              });
            }
          });

          if (allPortStbFuelCons.length > 0 || allMeFuelConsData.length > 0) {
            const maxFuelCons = !isSingleEngine
              ? allPortStbFuelCons.sort((a, b) => b - a)[0]
              : allMeFuelConsData.sort((a, b) => b - a)[0];

            const dataFuelConsFiltered = (
              !isSingleEngine ? allPortStbFuelCons : allMeFuelConsData
            ).filter((item) => item > 0);

            const avgFuelCons =
              dataFuelConsFiltered.reduce((acc, curr) => acc + curr, 0) /
              dataFuelConsFiltered.length;

            setSummary({
              max: maxFuelCons,
              avg: avgFuelCons,
            });

            // dataSeries.push({
            //   name: 'Max Fuel Cons.',
            //   type: 'line',
            //   yAxis: 0,
            //   marker: {
            //     enabled: false,
            //   },
            //   data: generateArrays(maxFuelCons, newCategories.length),
            //   tooltip: {
            //     valueSuffix: ' Liter',
            //   },
            //   color: hColors.red,
            //   zIndex: 5,
            // });

            // dataSeries.push({
            //   name: 'Avg. Fuel Cons.',
            //   type: 'line',
            //   yAxis: 0,
            //   marker: {
            //     enabled: false,
            //   },
            //   data: generateArrays(avgFuelCons, newCategories.length),
            //   tooltip: {
            //     valueSuffix: ' Liter',
            //   },
            //   color: hColors.yellow,
            //   zIndex: 5,
            // });
          }

          const newDataCategories = newCategories.map((item) =>
            moment(item * 1000).format('DD/MM/YY HH:mm')
          );

          const newDataSeries = dataSeries.map((serie: any) => {
            const data = [...serie.data].map((value: number | undefined) => {
              return typeof value === 'undefined'
                ? null
                : (value && typeof value === 'number') || value === 0
                ? Number(value.toFixed(2))
                : null;
            });

            const newData = fillNullValues(data);

            return {
              ...serie,
              data: newData,
              zoneAxis: 'x',
              zones: zoneChartType.includes(serie.type)
                ? generateSeriesDataZone({
                    data,
                    color: serie.color,
                    type: serie.type,
                  })
                : [],
            };
          });

          currChartOption = {
            ...currChartOption,
            xAxis: [
              {
                categories: newDataCategories,
                crosshair: true,
              },
            ],
            series: newDataSeries,
          };

          setDataChart(currChartOption);
        };

        createDataSeries();
      }

      // eslint-disable-next-spline react-hooks/exhaustive-deps
    }, [data, isSingleEngine, chartType]);

    // console.log('CHART ME FUEL CONS - raw data: ', data);
    // console.log('CHART ME FUEL CONS - data chart:', dataChart);

    return (
      <>
        {!isDailyReport && (
          <FlexHeader justify='space-between'>
            <TitleCard
              title='ME Fuel Consumption'
              subtitle={!isDailyReport && subtitleDottedLine}
            />
            <ChartTypeSwitch />
          </FlexHeader>
        )}
        <div ref={ref} style={{ height: '100%' }}>
          {dataChart && dataChart.series.length === 0 ? (
            <EmptyChart>
              <h3>Empty Data</h3>
              <p>Data series empty</p>
            </EmptyChart>
          ) : (
            <HighchartsReact
              containerProps={{
                style: {
                  height: isDailyReport ? '70%' : '90%',
                },
              }}
              highcharts={Highcharts}
              options={{
                ...dataChart,
                chart: {
                  zoomType: 'xy',
                  // height: height || 480,
                  // width,
                },
              }}
              allowChartUpdate={true}
              immutable={true}
            />
          )}

          {isDailyReport &&
            dataFilter.interval === 'HOUR' &&
            data &&
            dataTable && (
              <table
                className='custom-table-daily-report'
                style={{
                  width: isDailyReport ? '100%' : '90%',
                  margin: '0 auto',
                }}
              >
                <tbody>
                  <tr>
                    <th>Hour</th>
                    {columns.map((item) => (
                      <td key={item}>{item}</td>
                    ))}
                  </tr>
                  {!isSingleEngine ? (
                    <>
                      <tr>
                        <th>Port Fuel Cons.</th>
                        {dataTable.flowmeter &&
                        dataTable.flowmeter.data.length > 0
                          ? dataTable.flowmeter.data
                              .filter((item) => item.PORT_IN.timestamp)
                              .map((item: IFlowmeterData, idx: number) => (
                                <td key={`port-fuel-${idx}`}>
                                  {roundValue(item.PORT_FuelConsVolume || 0)}
                                </td>
                              ))
                          : generateArrays(
                              0,
                              columns ? columns?.length : 24
                            ).map((item) => <td>{item}</td>)}
                      </tr>
                      <tr>
                        <th>Stb. Fuel Cons.</th>
                        {dataTable.flowmeter &&
                        dataTable.flowmeter.data.length > 0
                          ? dataTable.flowmeter.data
                              .filter((item) => item.STARBOARD_IN.timestamp)
                              .map((item: IFlowmeterData, idx: number) => (
                                <td key={`stb-fuel-${idx}`}>
                                  {roundValue(
                                    item.STARBOARD_FuelConsVolume || 0
                                  )}
                                </td>
                              ))
                          : generateArrays(
                              0,
                              columns ? columns?.length : 24
                            ).map((item) => <td>{item}</td>)}
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <th>ME Fuel Cons.</th>
                        {dataTable.flowmeter &&
                        dataTable.flowmeter.data.length > 0
                          ? dataTable.flowmeter.data
                              .filter((item) => item.PORT_IN.timestamp)
                              .map((item: IFlowmeterData, idx: number) => (
                                <td key={`me-fuel-${idx}`}>
                                  {roundValue(item.ME_FuelCons || 0)}
                                </td>
                              ))
                          : generateArrays(
                              0,
                              columns ? columns?.length : 24
                            ).map((item) => <td>{item}</td>)}
                      </tr>
                    </>
                  )}
                  <tr>
                    <th>Avg. Fuel Cons.</th>
                    <td key='avg-fuel' colSpan={24}>
                      {roundValue(summary.avg || 0)}
                    </td>
                  </tr>
                  <tr>
                    <th>Max. Fuel Cons.</th>
                    <td key='max-fuel' colSpan={24}>
                      {roundValue(summary.max || 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
        </div>
      </>
    );
  }
);

export default HChartMeFuelCons;
