import { ISummary } from "../types/summary.type";
import moment from "moment";

export function useHardcodedSummary() {
  // Timestamps for GMT+8
  const MARCH_21_START = 1742486400; // March 21 00:00 GMT+8
  const MARCH_21_END = 1742572740; // March 21 23:59 GMT+8
  const MARCH_22_START = 1742572800; // March 22 00:00 GMT+8
  const MARCH_22_END = 1742659140; // March 22 23:59 GMT+8
  const MARCH_23_START = 1742659200; // March 23 00:00 GMT+8
  const MARCH_23_END = 1742745540; // March 23 23:59 GMT+8

  function checkDateInGMT8(timestamp: number): {
    date: number;
    month: number;
    year: number;
  } {
    // Convert timestamp to GMT+8
    const dateGMT8 = moment(timestamp * 1000).utcOffset(8);
    return {
      date: dateGMT8.date(),
      month: dateGMT8.month(),
      year: dateGMT8.year(),
    };
  }

  function generateHardcodedSummaryMarch21(): ISummary {
    return {
      flowmeter: {
        summary: {
          portEngineCons: 480,
          starboardEngineCons: 496,
          meEngineCons: 480 + 496, // Port + Starboard
          aeEngineCons: 6 + 390 + 0, // AE1 + AE2 + AE3
          portAverageFlow: 0,
          starboardAverageFlow: 0,
          aeAverageFlow: 0,
          bunkerEngineCons: 0,
          bunkerAverageFlow: 0,
        },
        data: [], 
        timestamps: [], 
      },
      rpm: {
        summary: {
          singleEngine: false,
          start: MARCH_21_START,
          end: MARCH_21_END,
          interval: 60,
          runningTime: {
            STARBOARD: 22800,
            PORT: 21900,
          },
          dataCount: {
            STARBOARD: 380,
            PORT: 365,
          },
          average: {
            STARBOARD: 986.631493131738,
            PORT: 944.5121091542179,
          },
          totalRPM: {
            STARBOARD: 374919.9673900604,
            PORT: 344746.9198412895,
          },
        }
      },
      gps: {
        summary: {
          interval: 60,
          start: MARCH_21_START,
          end: MARCH_21_END,
          totalPolyLength: 30027.786008036823,
          averageSpeed: 1.2635379262352124,
          calculatedSpeed: 2.9391878576467256,
          calculatedSpeedKMh: 5.44337729135065,
          distance: 23206.26726062181,
          movingTime: 19859,
          totalDistance: 30036.361467923038,
          totalDuration: 86280,
        },
        data: [],
        timestamps: [] 
      },
      ae: {
        summary: {
          fuelConsumption: {
            AE1: 6,
            AE2: 390,
            AE3: 0,
          },
          runningSeconds: {
            AE1: 4225,
            AE2: 86340,
            AE3: 0,
          },
          runningTime: {
            AE1: 4225,
            AE2: 86340,
            AE3: 0,
          },
          start: MARCH_21_START,
          end: MARCH_21_END,
          interval: 60,
        },
        data: [], 
        timestamps: []
      }
    } as unknown as ISummary;
  }

  function generateHardcodedSummaryMarch22(): ISummary {
    return {
      flowmeter: {
        summary: {
          portEngineCons: 1273,
          starboardEngineCons: 1255,
          meEngineCons: 1273 + 1255,
          aeEngineCons: 3 + 813 + 0,
          portAverageFlow: 0,
          starboardAverageFlow: 0,
          aeAverageFlow: 0,
          bunkerEngineCons: 0,
          bunkerAverageFlow: 0,
        },
        data: [], 
        timestamps: [], 
      },
      rpm: {
        summary: {
          singleEngine: false,
          start: MARCH_22_START,
          end: MARCH_22_END,
          interval: 60,
          runningTime: {
            STARBOARD: 40320,
            PORT: 40320,
          },
          dataCount: {
            STARBOARD: 672,
            PORT: 672,
          },
          average: {
            STARBOARD: 1195.9781794241142,
            PORT: 1137.5221854618617,
          },
          totalRPM: {
            STARBOARD: 803697.3365730047,
            PORT: 764414.9086303711,
          },
        }
      },
      gps: {
        summary: {
          interval: 60,
          start: MARCH_22_START,
          end: MARCH_22_END,
          totalPolyLength: 35230.57254098739,
          averageSpeed: 1.5224933890048709,
          calculatedSpeed: 1.6278670054045281,
          calculatedSpeedKMh: 3.014810457761169,
          distance: 24992.09752017964,
          movingTime: 42069,
          totalDistance: 35240.35480244458,
          totalDuration: 86280,
        },
        data: [], 
        timestamps: []
      },
      ae: {
        summary: {
          fuelConsumption: {
            AE1: 3,
            AE2: 813,
            AE3: 0,
          },
          runningSeconds: {
            AE1: 979,
            AE2: 86099,
            AE3: 0,
          },
          runningTime: {
            AE1: 979,
            AE2: 86099,
            AE3: 0,
          },
          start: MARCH_22_START,
          end: MARCH_22_END,
          interval: 60,
        },
        data: [], 
        timestamps: []
      }
    } as unknown as ISummary;
  }

  function generateHardcodedSummaryMarch23(): ISummary {
    return {
      flowmeter: {
        summary: {
          portEngineCons: 2400,
          starboardEngineCons: 2475,
          meEngineCons: 2400 + 2475,
          aeEngineCons: 63 + 777 + 0,
          portAverageFlow: 0,
          starboardAverageFlow: 0,
          aeAverageFlow: 0,
          bunkerEngineCons: 0,
          bunkerAverageFlow: 0,
        },
        data: [],
        timestamps: [],
      },
      rpm: {
        summary: {
          singleEngine: false,
          start: MARCH_23_START, // March 23 00:00
          end: MARCH_23_END, // March 23 23:59
          interval: 60,
          runningTime: {
            STARBOARD: 85920,
            PORT: 86160,
          },
          dataCount: {
            STARBOARD: 1436,
            PORT: 1436,
          },
          average: {
            STARBOARD: 1307.2477088161022,
            PORT: 1100.8253436500315,
          },
          totalRPM: {
            STARBOARD: 1871978.7190246582,
            PORT: 1580785.1934814453,
          },
        }
      },
      gps: {
        summary: {
          interval: 60,
          start: MARCH_23_START,
          end: MARCH_23_END,
          totalPolyLength: 59383.53005401233,
          totalDistance: 59458.99476569891,
          averageSpeed: 2.9899533551554858,
          totalDuration: 73500,
          movingTime: 66657,
          calculatedSpeed: 1.7317358806173626,
          calculatedSpeedKMh: 3.207175663387857,
          distance: 47924.28936061206,
        },
        data: [], 
        timestamps: [] 
      },
      ae: {
        summary: {
          fuelConsumption: {
            AE1: 63,
            AE2: 777,
            AE3: 0,
          },
          runningSeconds: {
            AE1: 30445,
            AE2: 86220,
            AE3: 0,
          },
          runningTime: {
            AE1: 1200,
            AE2: 86200,
            AE3: 0,
          },
          start: MARCH_23_START,
          end: MARCH_23_END,
          interval: 60,
        },
        data: [], 
        timestamps: [] 
      }
    } as unknown as ISummary;
  }

  function generateHardcodedSummary(timestamp: number): ISummary {
    const dateGMT8 = checkDateInGMT8(timestamp);
    console.log("Checking date in GMT+8:", dateGMT8);

    if (
      dateGMT8.date === 21 &&
      dateGMT8.month === 2 &&
      dateGMT8.year === 2025
    ) {
      console.log("Generating hardcoded summary for March 21 GMT+8");
      return generateHardcodedSummaryMarch21();
    }
    if (
      dateGMT8.date === 22 &&
      dateGMT8.month === 2 &&
      dateGMT8.year === 2025
    ) {
      console.log("Generating hardcoded summary for March 22 GMT+8");
      return generateHardcodedSummaryMarch22();
    }
    if (
      dateGMT8.date === 23 &&
      dateGMT8.month === 2 &&
      dateGMT8.year === 2025
    ) {
      console.log("Generating hardcoded summary for March 23 GMT+8");
      return generateHardcodedSummaryMarch23();
    }

    console.log("Defaulting to March 21 GMT+8 data");
    return generateHardcodedSummaryMarch21();
  }

  return { generateHardcodedSummary };
}
